import React from "react"
import Container from "~components/Container"

import "./index.css"

const Footer = () => (
  <footer className="footer tw-text-center tw-bg-black tw-text-white">
    <Container>
      © {new Date().getFullYear()}, Réalisé avec
      {` `}
      <a href="https://www.gatsbyjs.com" className="tw-font-bold">
        Gatsby
      </a>{" "}
      et avec ❤️.
    </Container>
  </footer>
)

export default Footer
