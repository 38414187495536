import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import Header from "~components/Header"
import Footer from "~components/Footer"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <div className="tw-bg-light tw-flex tw-flex-col tw-min-h-screen">
      <Header siteTitle={data.site.siteMetadata?.title || `Title`} />
      <main className="tw-flex-1">{children}</main>
      <Footer />
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
