import React from "react"
import cn from "classnames"

const Container = React.forwardRef(({ children, className, ...props }, ref) => {
  return (
    <div ref={ref} className={cn("tw-container", className)} {...props}>
      {children}
    </div>
  )
})

export default Container
