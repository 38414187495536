import React, { useState } from "react"
import styled from "styled-components"
import cn from "classnames"
import { Link } from "gatsby"
import PropTypes from "prop-types"

import { ReactComponent as LogoIcon } from "~images/svg/logo-max.svg"

import "./index.css"

const BurgerButton = ({ className, children, ...props }) => {
  return (
    <div
      className={cn(
        "tw-fixed tw-z-[2000] tw-w-[55px] tw-h-[55px] tw-flex tw-items-stretch tw-content-start tw-bg-black tw-text-white tw-bottom-[60px] tw-right-5 tw-rounded-full tw-border-2 tw-border-[#ddd] tw-p-2.5 tw-shadow-gray-500 tw-shadow-xl",
        className
      )}
      {...props}
    >
      {children}
    </div>
  )
}

const Burger = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: relative;

  span,
  &::before,
  &::after {
    position: absolute;
    display: block;
    border-radius: 9999px;
    width: calc(16 / 24 * 100%);
    height: calc(2 / 24 * 100%);
    background-color: currentColor;
  }

  span {
    opacity: 1;
    transition: opacity 200ms ease-in-out;
  }

  &::before,
  &::after {
    content: "";
    transition: transform 0.2s ease-in-out;
  }

  &::before {
    transform: translateY(-300%);
  }

  &::after {
    transform: translateY(300%);
  }

  &.active {
    span {
      opacity: 0;
    }

    &::before {
      transform: rotateZ(45deg);
    }

    &::after {
      transform: rotateZ(-45deg);
    }
  }
`

const Header = React.memo(() => {
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false)

  return (
    <>
      <header className="header">
        <div className="tw-container tw-h-full">
          <Link
            className="tw-absolute tw-top-1/2 tw-transform tw--translate-y-1/2"
            to="/"
            aria-label="logo du site surlespasdemax.fr"
          >
            <LogoIcon height="56" width="100%" />
          </Link>
          <nav className="tw-flex tw-items-center tw-justify-end tw-h-full">
            <ul className="tw-items-stretch tw-space-x-8">
              <li>
                <Link className="menu-link" to="/" activeClassName="active">
                  Accueil
                </Link>
              </li>
              <li>
                <Link
                  className="menu-link"
                  to="/visites"
                  activeClassName="active"
                >
                  Visites
                </Link>
              </li>
              <li>
                <Link
                  className="menu-link"
                  to="/programme"
                  activeClassName="active"
                >
                  Programme
                </Link>
              </li>
              <li>
                <Link
                  className="menu-link"
                  to="/contact"
                  activeClassName="active"
                >
                  Contacts pour réservation
                </Link>
              </li>
            </ul>
          </nav>
        </div>
      </header>

      <nav className={cn("mobile-menu", { open: isMobileMenuOpen })}>
        <ul className="tw-flex tw-items-start tw-flex-col tw-space-y-4">
          <li>
            <Link className="menu-link" to="/" activeClassName="active">
              Accueil
            </Link>
          </li>
          <li>
            <Link className="menu-link" to="/visites" activeClassName="active">
              Visites
            </Link>
          </li>
          <li>
            <Link
              className="menu-link"
              to="/programme"
              activeClassName="active"
            >
              Programme
            </Link>
          </li>
          <li>
            <Link className="menu-link" to="/contact" activeClassName="active">
              Contacts pour réservation
            </Link>
          </li>
        </ul>
      </nav>

      <BurgerButton
        role="button"
        className="lg:tw-hidden"
        onClick={() => {
          setMobileMenuOpen(state => !state)
        }}
      >
        <Burger className={cn({ active: isMobileMenuOpen })}>
          <span />
        </Burger>
      </BurgerButton>
    </>
  )
})

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
